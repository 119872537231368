import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import useSlug from '~/hooks/useSlug';
import { QueryParam } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';
import { setScrollPos } from '~/redux-tools/store/scroll/actions';
import { cleanCheckIn } from '~/redux-tools/store/localOrders/actions';
import { handlePageRefresh, isThisChromeVersionSupported, handleRedirectWithRetry } from '~/utils';

import useMenuProducts from './useMenuProducts';

function useMenuRedirects(): {
  showBagFlash: boolean;
} {
  const history = useRouter();
  const dispatch = useDispatch();

  const scrollPos = useSelector((state: IApplicationState) => state.scroll.data);
  const { isMenuListEmpty } = useSelector((state: IApplicationState) => state.menus);
  const { settings, error: errorInfo } = useSelector((state: IApplicationState) => state.establishment);
  const { mode: localOrder, store_id: localOrderStore } = useSelector((state: IApplicationState) => state.localOrders);

  const [showBagFlash, setShowBagFlash] = useState(false);

  const slug = useSlug();
  const { allProducts } = useMenuProducts();

  useEffect(() => {
    handlePageRefresh();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const isRedirectFromBag = urlParams.get(QueryParam.bag) === 'empty';

    if (isRedirectFromBag) {
      setShowBagFlash(true);
    }
  }, []);

  useEffect(() => {
    if (settings && !!settings.id && !!settings.store_code && localOrder && localOrderStore) {
      const isTheSameEstablishment = settings && [settings.id, settings.store_code].includes(localOrderStore);

      if (!isTheSameEstablishment && typeof window !== 'undefined') {
        dispatch(cleanCheckIn());

        window.location.assign('/');
      }
    }
  }, [dispatch, localOrder, localOrderStore, settings]);

  useEffect(() => {
    if (errorInfo.length > 0) {
      handleRedirectWithRetry({
        history,
        errorInfo,
        id: settings?.id,
        errorMenu: 'Esse estabelecimento não possui nenhum cardápio cadastrado'
      });
    }
  }, [errorInfo, history, settings, slug]);

  if (scrollPos.y > 0 && window.location.hash === '' && isThisChromeVersionSupported()) {
    setTimeout(() => scrollTo({ top: scrollPos.y, behavior: 'smooth' }), 50);
  }

  if (typeof window !== 'undefined') {
    const isAbleToScroll =
      window.location.hash === '' && window.pageYOffset === 0 && allProducts.length > 0 && scrollPos.y > 0;

    if (isAbleToScroll) {
      setTimeout(() => {
        return scrollTo({ top: scrollPos.y, behavior: 'auto' });
      }, 0);

      setTimeout(() => {
        dispatch(setScrollPos({ x: 0, y: 0 }));
      }, 200);
    }
  }

  return { showBagFlash };
}

export default useMenuRedirects;
